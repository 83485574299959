import { useEffect, useState } from "react";
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { useParams } from "react-router";
import { HeroTitle } from "../Components/HeroSection/HeroElements";
import LoadingLine from "../modules/LoadingLine";
import { useHistory } from "react-router";
import axios from "axios";
import BorderButton from "../modules/Buttons";
import {useQuery} from "react-query";

function Post( ) {
    Fancybox.bind(".fansy a", {});
    const {slug} = useParams();
    const hist = useHistory();
    const preloader = [33,12,55,67,94,35,86,75,14,98]
    const [dataset ,setData]  = useState(0);
    const dataId = [];
    const [dataNext, setDataNext] = useState(slug);
    const [dataPrev, setDataPrev] = useState(slug);
    function innerhtml(text) { return {__html: text}; };
    const { isLoading, data } = useQuery(
        'posts',
        async () => {
            return fetch("https://wdev.smart-ui.pro/wp-json/wp/v2/posts?_fields=acf,title,id").then(resp => resp.json())
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );
    function handleNext() {
        hist.push(`/portfolio/${dataNext}`)
    }
    function handlePrev() {
        hist.push(`/portfolio/${dataPrev}`)
    }
    useEffect(() => {
        data && data.map((item)=>dataId.push(`${item.id}`))
        setDataNext(dataId[dataId.indexOf(slug)+1])
        setDataPrev(dataId[dataId.indexOf(slug)-1])
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        async function fetchPosts () {
            let response = undefined;
            try{
                response = await axios.get(`https://wdev.smart-ui.pro/wp-json/wp/v2/posts/${slug}?_fields=title,rendered,content,id`);
            }catch(err) {
                console.log(err)
            }
            (response != undefined) ? setData(response.data) : hist.push("/portfolio");
        }
        fetchPosts();
        const css = document.createElement('link');
        css.href="https://wdev.smart-ui.pro/wp-includes/css/dist/block-library/style.min.css";
        css.rel="stylesheet";
        css.type="text/css";
        document&&document.body&&document.body.append(css);
        return(()=>{
            css&&css.remove();
            Fancybox.close();
        }
        )
   }, [slug])
    return(
        <section style={{marginTop:'150px'}}>
        <div className={'portfolio_container'}>
            <BorderButton onClick={() => window.history.go(-1)}>Go Back</BorderButton>
              <HeroTitle style={{textAlign:'center' , marginBottom:"50px"}}>
                 {dataset?.title?dataset?.title?.rendered: <LoadingLine h="67px" w={77 + "%"} d={ 2*1200 + "ms"}/>}
             </HeroTitle>
             <div>
             { dataset?.content?.rendered?  <p dangerouslySetInnerHTML={innerhtml(dataset?.content?.rendered)}/>:<>

                        {preloader.map((item, i )=>{
                            return(
                                <LoadingLine h="38px" w={item + "%"} d={ i*1200 + "ms"} key={i} />
                            )
                        })}
               </> }
             </div>
            <div className={'buttonBar'}>
                <BorderButton onClick={() => handlePrev()}>Previous</BorderButton>
                <BorderButton onClick={() => handleNext()}>Next</BorderButton>
            </div>
        </div>
    </section>
    )
}

export default Post ;