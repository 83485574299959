export const variables = {
    fontSize: '16px',
    mainFont: '"Poppins", sans-serif',
    fontColor: '#302E2D',
    mainBg: '#fff',
    accentColor: '#00D9A6',
    upworkGreen: '#6fda45',
    secondBg: '#BCBCBC',
    menuColor: '#000',
    btColor: '#202020',
    secondFontColor: '#fff',
    placeholderFont: '#E3E3E3',
    containerWidth: '1310px',
    mobileXsMin: '320px',
    mobileMin: '480px',
    mobileMax: '576px',
    tabletMin: '768px',
    desktopMin: '992px',
    desktopMd: '1024px',
    wideScreenMin: '1200px',
    wideScreenXl: '1440px',
    wideScreenMax: '1800px',
    UPWORK_LINK: 'https://www.upwork.com/o/companies/~01e25310f9dbd13dbb/',
    LINKEDIN_LINK: 'https://www.linkedin.com/in/smart-ui-company/'
};