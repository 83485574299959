import React from 'react';
import StyledNotFound from "../modules/StyledNotFound";
import { useHistory } from "react-router";
const NotFound = () => {
    const hist = useHistory()
    return (
        <StyledNotFound>
            <h1>
                Sorry
            </h1>
            <h2>Error 404: Page Not Found</h2>
            <button onClick={() => hist.push('/') }>Go Home</button>
        </StyledNotFound>
    );
};

export default NotFound;