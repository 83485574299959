import styled from "styled-components";

const StyledNotFound =  styled.div`
margin-top: 150px;
text-align: center;
h1 {
    font-size: 40px;
}
h2 {
    font-size: 34px;
}
button {
    padding: 10px 15px;
    color: white;
    background: #28b674;
    border-radius: 5px;
    margin-top: 10px;
}
`


export default StyledNotFound;