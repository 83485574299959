import ContactBg1 from '../../Images/bg-4.png';
import ContactBg2 from '../../Images/bg-5.png';
import ContactBg3 from '../../Images/bg-6.png';
import UpWork from '../../Images/icon/upwork.svg';
import LinkedIn from '../../Images/icon/linkebIn.svg';
import Phone from '../../Images/icon/phone.svg';
import Mail from '../../Images/icon/mail.svg';

export const contactData={
    bg1: ContactBg1,
    bg2: ContactBg2,
    bg3: ContactBg3,
    contactInfoCity:"Kharkov City, Ukraine",
    contactInfoAve:"14, Lopatyns'kyi Ln",
    contactInfoWD:"Monday-Friday: 09.00-23.00",
    contactInfoWeekend:"Saturday-Sunday: 11.00-16.00",
    upWorkLogo: UpWork,
    altUpWork: "UpWork",
    linkedInLogo: LinkedIn,
    altLinkd:"LinkedIn",
    phoneLogo:Phone,
    altPhone:"Phone",
    phoneContact:"+38 (093) 504 63 34",
    mailLogo:Mail,
    altMail:"Mail",
    mailContact:"info@smart-ui.pro",
};