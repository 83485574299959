import Slide1 from "../../Images/logo-1.png";
import Slide2 from "../../Images/logo-2.png";
import Slide3 from "../../Images/logo-3.png";
import Slide4 from "../../Images/logo-4.png";
import Slide5 from "../../Images/logo-5.png";
import Slide6 from "../../Images/logo-6.png";

export const heroSliderImg = [
    {
        slideImg: Slide1,
        alt:'company'
    },
    {
        slideImg: Slide2,
        alt:'company'
    },
    {
        slideImg: Slide3,
        alt:'company'
    },
    {
        slideImg: Slide4,
        alt:'company'
    },
    {
        slideImg: Slide5,
        alt:'company'
    },
    {
        slideImg: Slide6,
        alt:'company'
    },
];
