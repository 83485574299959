export const formData={
    name:"Your name",
    phName:"Your name",
    email:"Your Email",
    phEmail:"Your Email",
    textarea:"Your message",
    phTextarea:"Write your message for us",
    btn:"Send a message",
    errorName:"Enter the name!",
    errorEmail:"Enter the email!",
    errorMessage:"Enter the message!",
    successMessage:"Thank you! We will contact you soon."
}