
import HeroSection from "../Components/HeroSection";
import SliderCompany from "../Components/SliderCompany";
import WhatWeDo from "../Components/WhatWeDo";
import Tools from "../Components/Tools";
import Advantages from "../Components/Advantages";
import Reviews from "../Components/Reviews";


const Home = () => { 
    return (
        <>
           
           
            <HeroSection/>
            <SliderCompany/>
            <WhatWeDo/>
            <Tools/>
            <Advantages/>
            <Reviews/>
         
        </>
    );
};
export default Home;