import styled from "styled-components";

const BorderButton =  styled.button`
padding:10px 20px;
border: solid var(--primary-color) 1px;
position: relative;
transition: 0.3s; 
border-radius: 25px;
overflow: hidden;
margin: 0 auto;
max-width: 300px;
color: #28b674;
background: transparent;
    &::after{
            content: "";  
            background-color: rgba(224, 224, 224, 0.2);
            z-index:-1;
            position: absolute; 
            left:0;
            width: 0%;
            top: 0;
            bottom: 0;
            transition: 0.3s;
        }
        &:hover{ 
            &::after{
                width: 100%; 
            } 
`


export default BorderButton;