import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }

  :root{
    --primary-color:#00D9A6;
  }


  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  :focus, :active {
    outline: none;
  }

  a:focus, a:active {
    outline: none;
  }

  nav, footer, header, aside {
    display: block;
  }

  input, button, textarea {
    font-family: inherit;
  }

  input::-ms-clear {
    display: none;
  }

  button {
    cursor: pointer;
  }

  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  a, a:visited {
    text-decoration: none;
    display: inline-block;
  }

  a:hover {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  ul li {
    list-style: none;
  }
  .mobileMenu.active {
    .secondLine {
      transform: translate(-36px);
      border-radius: 20%;
    }
    .firstLine {
      transform: rotate3d(1, 1, 4, 45deg);
      top: 14px;
    }
    .thirdLine {
       transform: rotate3d(1, 1, -4, 45deg);
       top: 14px;
       border-radius: 20%;
    }
  }
  .firstLine {
     width: 100%;
     height: 4px;
     border-radius: 25%;
     background: black;
     transition 0.3s;
     position: absolute;
     top: 5px;
   }
   .secondLine {
     width: 100%;
     transition 0.2s;
     height: 4px;
     border-radius: 25%;
     background: black;
     position: absolute;
     top: 47%;
   }
   .thirdLine {
      width: 100%;
      height: 4px;
      transition 0.3s;
      border-radius: 25%;
      background: black;
      position: absolute;
      bottom: 5px;
   }
  .portfolio_container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .portfolio_container img {
    margin: 0 auto;
  }
  .portfolio_list {
    display: flex;
    flex-wrap: wrap;
  }
  .portfolio_item {
    margin: 0 15px 20px 15px;
    width: 31%;
  }
  .buttonBar {
    display: flex;
    justify-content: space-between;
  }
  .buttonBar button{
    margin: 0;
  }
  @media (max-width: 1320px) { 
    .portfolio_item {
        margin: 0 0 20px 0;
        width: 49%;
    }
    .portfolio_list {
        justify-content: space-between;
    }
  }
  @media (max-width: 660px) { 
    .portfolio_item {
        margin: 0 0 20px 0;
        width: 100%;
    }
  }
`;