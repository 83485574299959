import styled from 'styled-components';

export const SliderWrap = styled.section`
 display: flex;
  justify-content: center;
  padding: 30px 15px;
 
`;

export const SliderContent = styled.div`
  max-width: 995px;
  width: 100%;
  overflow: hidden;
`;

