import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./pages/index";
import React, {useState} from "react";
import NotFound from "./pages/NotFound";
import Portfolio from "./pages/portfolio";
import Portfoliopost from "./pages/Portfoliopost";
import Contact from "./Components/Contact";
import {GlobalStyle} from "./GlobalStyle";
import {DefaultStyle} from "./Default";
import Header from "./Components/Header";
import {QueryClient, QueryClientProvider} from "react-query";

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen((!isOpen));
    };
    const queryClient = new QueryClient()
    return (
            <QueryClientProvider client={queryClient}>
                <GlobalStyle/>
                <DefaultStyle/>
                <Router>
                    <Header isOpen={isOpen} toggle={toggle}/>
                    <Switch>
                        <Route path='/' component={Home} exact/>
                        <Route path='/portfolio' component={Portfolio} exact/>
                        <Route path='/portfolio/:slug' component={Portfoliopost} exact/>
                        <Route path='/'><NotFound/></Route>
                    </Switch>
                </Router>
                <Contact/>
            </QueryClientProvider>

    );
}

export default App;
