import React, {useEffect} from "react";
import {useQuery} from "react-query";
import {HeroTitle} from "../Components/HeroSection/HeroElements";
import PortfolioCard from "../Components/portfolio/PortfolioCard";

const Portfolio = () => {
    const {isLoading, data} = useQuery(
        'posts',
        async () => {
            return fetch("https://wdev.smart-ui.pro/wp-json/wp/v2/posts?per_page=100&_fields=acf,title,id").then(resp => resp.json())
        },
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            placeholderData: [0, 0, 0, 0, 0, 0],
        }
    );
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <>
            <section style={{marginTop: '150px'}}>
                <div className={"portfolio_container"}>
                    <HeroTitle style={{textAlign: 'center', marginBottom: "50px"}}>
                        Our portfolio
                    </HeroTitle>
                    <div className={'portfolio_list'}>
                        {!isLoading ? data.map((post, i) => (
                            <div className={'portfolio_item'} key={i + "a"}><PortfolioCard
                                dataWp={post} i={i}/></div>)) : ''}
                    </div>
                </div>
            </section>
        </>
    );
};
export default Portfolio;
