export const reviewsData1 = {
  date: "1522 hours",
  text: "You will not regret hiring Alex. He was working for us (Weorder) for over two years and delivered the craziest (in a good sense) CSS designs stretching the looks of customised apps to the extent I did not know was possible.",
};

export const reviewsData2 = {
  date: "Fixed Price",
  text: "The team delivered once again on time and on budget as expected. The team can tap to a multiple variety of skills so I highly recommended it.",
};

export const reviewsData3 = {
  date: "6 hours",
  text: "Alex is an absolute dream to work with. He’s incredibly efficient and has fantastic code skills. Working with him and his team was a very pleasant experience! I will absolutely use him again!",
};

export const reviewsData4 = {
  date: "Fixed Price",
  text: `Everything was done great, in a timely manner. They were more than helpful with all the questions. I am giving them more jobs! Go with these guys if you have a need for web site design`,
};
